@mixin opc__updates__checkout-confirmation {
  .opc__confirm {
    .confirmation-panel {
      input[type='text'],
      input[type='tel'],
      input[type='password'] {
        @include swap_direction(padding, 5px 15px);
        width: 100%;
        border: 1px solid $color-black;
        height: calc(3em - -2px);
        margin-bottom: 15px;
        &.error {
          border: 1px solid $color-red;
        }
      }
      input[type='submit'] {
        background-color: $color-pink;
        color: $color-white;
        width: 100%;
        padding: 15px;
        height: auto;
        line-height: 1;
        left: 0px;
      }
      .payment-info-panel__title {
        @include swap_direction(padding, 15px);
        border-bottom: 1px solid $color-gray-border;
        border-top: 2px solid $color-black;
        background-color: $color-gray-background;
      }
      #confirmation-number {
        @include swap_direction(margin, 1.5em 0 1em 0);
        text-align: center;
        font-weight: bold;
        a {
          @include swap_direction(margin, 0.3em 0);
          display: block;
          color: $color-black;
          text-decoration: none;
          font-size: 16px;
        }
      }
      #confirmation-email {
        @include swap_direction(padding, 0 2em 2em 2em);
        @include swap_direction(margin, 0);
      }
      .empty-wrapper__link {
        @include swap_direction(margin, 0 0 15px 0);
        border: 2px solid $color-black;
        cursor: pointer;
        font-weight: 700;
        color: $color-black;
        text-transform: uppercase;
        line-height: 40px;
        .wrapper-link {
          text-decoration: none;
          cursor: pointer;
          text-align: center;
          display: block;
          color: $color-black;
        }
      }
      .return-container {
        margin-top: 30px;
        border-top: 2px solid $color-black;
        h3 {
          @include swap_direction(padding, 8% 0);
          font-size: 16px;
          text-align: center;
          font-weight: bold;
        }
      }
      .print-buttons {
        text-align: center;
        margin-bottom: 20px;
      }
      .password_field_set {
        position: relative;
        label {
          display: none;
        }
        #forgot-pass {
          @include swap_direction(margin, 0 0 20px 0);
          font-size: 16px;
          font-weight: bold;
          color: $color-pink;
          text-decoration: underline;
          cursor: pointer;
          text-transform: uppercase;
        }
      }
      .pc_email_container,
      .email-address {
        @include swap_direction(margin, 15px 0);
        .label-content {
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
          color: $color-black;
        }
      }
      .privacy_policy_container {
        @include swap_direction(margin, 20px 0);
      }
      .checkout__panel--header {
        .checkout__panel--heading {
          @include swap_direction(padding, 15px 10px);
          border-bottom: 1px solid $color-gray-border;
          border-top: 1px solid $color-gray-border;
          background-color: $color-gray-background;
          font-weight: bold;
          text-align: center;
        }
      }
      .create_account_benefits {
        ul {
          list-style-type: none;
          li {
            &:before {
              content: '-';
              text-indent: -5px;
              margin-#{$rdirection}: 5px;
            }
          }
        }
      }
      .checkout__panel--heading {
        font-family: $brandon-text-bold;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      .legal_requirements_header {
        display: none;
      }
      #confirmation-email {
        @include swap_direction(padding, 0 10px 30px);
      }
      .error_messages {
        li {
          margin-left: 0px;
        }
      }
      .toggle-password {
        position: absolute;
        top: 11px;
        #{$rdirection}: 10px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .mobile-breadcrumb {
      display: block;
    }
    .recommended-products-panel {
      display: block !important;
      padding-top: 20px;
      .recommended-product-items {
        .recommended-item {
          width: 100%;
          padding-bottom: 40px;
          .add_button {
            input[type='submit'] {
              @include swap_direction(margin, 15px 0px);
              float: right;
            }
          }
          .add_button {
            float: none;
            text-align: $ldirection;
          }
          .product_name {
            min-height: auto;
          }
          .cart-item__size,
          .product_subname {
            display: none;
          }
          .formatted_price {
            @include swap_direction(padding, 12px 0);
            text-align: $ldirection;
            font-weight: bold;
          }
          .product__favorite {
            text-decoration: none;
          }
          .icon-heart_outline {
            @include swap_direction(padding, 5px 0);
            @include swap_direction(margin, 0 0 13px 5px);
            font-size: 16px;
          }
          .description {
            float: $ldirection;
          }
        }
      }
    }
    .border_divider {
      border-bottom: 2px solid $color-black;
      margin-bottom: 20px;
    }
    .trans_order_id {
      display: block;
      font-size: 16px;
    }
    &.elc-user-state-logged-in {
      .confirmation-panel {
        .return-container {
          border-top: none;
          margin-top: 0px;
        }
        .checkout__content {
          .border_divider {
            margin-bottom: 0px;
          }
          #print-order {
            display: none;
          }
        }
      }
    }
    &.elc-user-state-anonymous {
      .info-msg {
        padding-#{$ldirection}: 30px;
      }
    }
    .guest_sign-in-legal-link {
      .sign-in-legal-link {
        padding-#{$ldirection}: 22px;
      }
    }
  }
}

.utq_banner {
  margin-top: 20px;
  padding: 0px 10px;
}
