#search-results {
  #refinements {
    line-height: 1.231;
    width: 100%;
    float: right;
    div.accordion-header {
      background: url('/media/images/global/icon-dropdown.png') no-repeat scroll 96% 7px #f8f8f8;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: 1px solid #ccc;
      text-transform: capitalize;
      font-weight: bold;
      padding: 5px 30px 5px 5px;
      float: left;
      border-bottom: none;
    }
    & > div {
      border-top: 1px solid #ccc;
    }
    .accordion-content {
      padding: 4px 4px 10px;
      background-color: #f8f8f8;
      float: right;
      width: 100%;
      #breadcrumbs {
        font-size: 12px;
        font-family: arial;
        letter-spacing: 0.01em;
        .breadcrumb {
          float: left;
          padding-left: 14px;
          position: relative;
          width: auto;
          margin: 2px 0 2px 8px;
        }
        .breadcrumb a {
          color: #444444;
          display: block;
          text-decoration: none;
        }
        .breadcrumb .close_x {
          color: black;
          font-weight: bold;
          left: 0;
          position: absolute;
          top: 1px;
        }
      }
    }
  }
  #product-results {
    border: 1px solid #ccc;
    float: right;
    .pagination-container {
      .pagination a {
        padding: 5px;
      }
    }
  }
}
/* footer design */
.site-footer-contact {
  .site-footer-contact__item.customerservice_width {
    width: 100%;
  }
  @media (min-width: 768px) {
    .site-footer-contact__item.customerservice_width {
      width: 50%;
      a {
        margin-left: 20%;
      }
    }
  }
}

.spp-mobile-page .spp__share {
  overflow: hidden;
}
