.viewcart-paypal-button {
  .terms-conditions {
    margin-top: 10px;
    input {
      position: inherit;
      float: $ldirection;
    }
    &-txt {
      margin-#{$ldirection}: 15px;
    }
    .error {
      color: $color-red;
    }
  }
}

#viewcart {
  .checkout__content {
    #viewcart-panel {
      .top-viewcart-buttons {
        &.hasPaypal {
          display: block;
          .add-button {
            display: none;
          }
        }
      }
    }
  }
}

.pg-checkout {
  .site-footer {
    background: $color-gray-background;
    .site-footer {
      &__wrap {
        background: none;
        color: $color-black;
      }
      &-bottom {
        background: $color-gray-background;
        color: $color-black;
        &__menu {
          .menu {
            &__item {
              a {
                color: $color-black;
              }
            }
          }
        }
        &__trademark {
          color: $color-black;
        }
      }
    }
  }
}

.mpp-header-content-block {
  .content-block {
    &--default {
      .position {
        &--bare {
          .content-block {
            &__picture {
              position: relative;
            }
            &__content-wrapper {
              position: absolute;
              top: 0;
            }
          }
        }
      }
    }
  }
}
