/**
 * @file ru_mobile.scss
 *
 * \brief Pull all parts of the RU-MOBILE theme together into one file
 */
$color-vkontakte: #4680c2;

html,
button,
input,
select,
textarea {
  font-family: Arial, Verdana, sans-serif;
}

@import 'overrides/overrides';
@import '../../bb_base/scss/bobbibrown-base';
@import 'global/fontsize';
@import 'sections/_gwp_mobile';

// SMACSS/sections/account
@import 'sections/mobile/account/account';

// SMACSS../../emea_base/scss/emea_search_mobile
@import '../../emea_base/scss/emea_search_mobile';
@import '../../emea_base/scss/sections/checkout/checkout-samples';

// SMACSS/sections/checkout
@import 'sections/mobile/checkout/checkout';
@import 'sections/common/checkout/pickpoint';
@import 'sections/all';
@import 'sections/mobile/all';

//see all offers
@import '../../emea_base/scss/sections/mobile/checkout_offers_promo';

// Live Engage
@import 'components/live_person';

//social login
@import 'components/social_login';
@import 'sections/social_login_v2';

// Book Appointment.
@import '../../emea_base/scss/sections/mobile/_appointment-booking';
@import '../../emea_base/scss/sections/shared/_appointment-booking';
@import '../../emea_base/scss/_emea_opc_mobile';

@import 'sections/loyalty_all';
