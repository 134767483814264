/* Checkout refinements */
$color-gray-bglight: #fbfbfb;
$color-gray-bgmedium: #eff1f0;
$color-gray-bglightest: #fdfdfd;

@import 'sections/mobile/all';
@import 'sections/shared';
@import 'sections/mobile/opc_updates/opc__updates';
$co_updates: false !default;

@if $co_updates == true {
  @include co_updates;
}

@import 'sections/checkout/_co_emea_update_checkout.scss';
