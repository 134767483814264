$xsmall-up: max-width 320px;

.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

select::-ms-expand {
  display: none;
}

#product-results {
  .product-thumb--price {
    line-height: 20px;
  }
}

#search-results {
  .endeca-product-result {
    .product-thumb {
      .product-thumb__cta {
        height: auto;
      }
      .product__button--add-to-bag {
        height: auto;
        float: left;
        margin-top: 5px;
      }
    }
  }
}

.site-footer {
  .site-footer__main {
    .site-email-signup__field {
      @include breakpoint($medium-range) {
        width: 58%;
      }
    }
  }
}

#appointment-book-sections {
  .appt-book-section {
    .service-select {
      .services-container {
        .service-head {
          height: 47px;
        }
      }
    }
  }
}

.appt-book {
  .confirmation-container {
    .artist {
      display: none;
    }
  }
  #account-my-appointments {
    .appointment-details {
      .artist {
        display: none;
      }
    }
  }
  .appt-book-page-header {
    .appt-book-page-header-content h2 {
      font-size: 18.5px;
    }
  }
  .book-appt-container {
    .registration__email-list {
      height: auto !important;
    }
  }
}

.appt-book.confirmation {
  .appt-book-page-header {
    .appt-book-page-header-content {
      .section-header__inner {
        font-size: 21px;
      }
    }
  }
}

.slide_hero_module {
  .product-thumb__abstract {
    .product-thumb__headline {
      h3.product_brief__header {
        word-wrap: break-word;
      }
    }
    .product-thumb--price {
      margin: 24px 0 11px 0 !important;
    }
  }
}

.store-locator {
  #map-canvas {
    .info_window.store-locator__tooltip {
      .store-locator__tooltip-phone {
        font-size: 12px;
        letter-spacing: 0.7px;
      }
    }
  }
}

.touch select {
  background: url(/media/images/global/icon-dropdown.png) no-repeat right 5px top 9px !important;
}

.checkout__content {
  .cart-item {
    .cart-item__total {
      display: block;
    }
  }
}

.tabbed-products-formatter {
  &__header {
    .content-block__line--header {
      .style--bare {
        font-size: 35px;
      }
    }
  }
  &__content {
    .tabbed-products-block__tabs {
      .tabbed-products-block__tab {
        font-size: 10px;
        padding: 16px 10px;
      }
    }
  }
}
