#offer-code-panel {
  #one-offer-only {
    font-size: 13px;
    span {
      display: inline;
      float: right;
      .offer-code__see-all-offers {
        margin-top: 0;
        letter-spacing: 0;
        padding: 0;
        border-left: none;
        border-right: none;
        border-top: none;
      }
    }
  }
  input[type='submit'].offer_submit {
    width: 100%;
  }
}

#cboxContent {
  .offers-hero {
    p {
      margin: 0 0 10px 0;
    }
    &__title {
      font-size: 16px;
      line-height: 26px;
    }
    &__text {
      line-height: 26px;
      font-size: 14px;
    }
    ul {
      li {
        line-height: 24px;
      }
    }
    .offers-hero__image-container {
      width: 50%;
      float: right;
      margin: 0 10px 0 10px;
      padding: 0;
      position: relative;
      .offers-hero__image {
        max-width: 100%;
        position: relative;
        right: 0px;
        top: 0px;
        width: 100%;
        z-index: 1;
      }
    }
  }
  .offers-details {
    .offers-details-item {
      &__header {
        font-size: 15px;
        line-height: 26px;
      }
      &__text {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
