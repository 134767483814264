.checkout {
  &.samples,
  &.viewcart,
  &.checkout-confirmation-page {
    margin-left: 20px;
    margin-right: 20px;
  }
  #links-panel {
    .link {
      border: none;
      display: block;
      padding: 10px 0 0;
    }
  }
  .offer-code-panel {
    &__content {
      #one-offer-only {
        margin: 18px 0 14px 0;
        display: block;
        font-style: normal;
      }
    }
  }
  .top-viewcart-buttons {
    margin-bottom: 10px;
    .viewcart-buttons-panel {
      .continue-shopping {
        display: block !important;
        visibility: visible;
      }
    }
  }
  .viewcart-buttons-panel {
    .continue-shopping.disabled {
      color: $color-gray-light;
      border-bottom: 1px solid $color-gray-light;
    }
    .continue-checkout.disabled {
      color: $color-white;
      background: $color-gray-light;
    }
  }
  fieldset {
    .address-form__item {
      padding: 0;
      .selectbox {
        margin-bottom: 20px;
      }
    }
    .phone_label_container {
      margin-bottom: 20px;
    }
  }
  #choose-address {
    .address-select {
      margin: 10px 0;
    }
  }
  .checkout__sidebar {
    section {
      margin-bottom: 10px;
    }
  }
  .recommended-product-items {
    .recommended-item {
      width: 47%;
      margin-right: 3%;
    }
    .add_button {
      margin-top: 10px;
    }
    .grey_button {
      font-size: 0.55em;
    }
  }
  .product.deactivate {
    .sample-select-button {
      background: $color-gray-light;
    }
  }
  #review-address-info {
    h4.checkout__subtitle {
      position: relative;
      padding-top: 10px;
      font-size: 13px;
      a.change_link {
        position: absolute;
        right: 0;
      }
    }
  }
  .checkout-buttons-container {
    a.btn {
      width: 100%;
      padding: 0.75em 1em;
      line-height: 1;
      height: auto;
      margin: 20px 0;
    }
  }
  .shipping-address-info {
    #address_form_container {
      border: none;
      padding: 0;
      margin-top: 10px;
    }
  }
  .shipping-page-info-panel {
    .checkout-buttons-container {
      margin: 0 0 20px 0;
    }
  }
  #checkout_review {
    .checkout__panel-title-container.checkout-header {
      margin: 15px 0;
    }
    .payment-properties {
      margin-bottom: 15px;
    }
  }
  .promotions-container {
    .newsletter-info {
      h3.newsletter-info__header {
        margin-left: -21px;
      }
      p.newsletter-info__text--new,
      .newsletter-info__text--provid {
        margin-left: auto !important;
      }
      .newsletter-info__item {
        margin-left: -10px;
      }
    }
  }
}

.payment-properties {
  .payment-group-options-cc {
    margin: 1em 0;
    .payment-item-wrapper {
      margin-bottom: 20px;
      input[type='radio'] ~ label::after,
      input[type='radio'] ~ label::before {
        top: 10px;
      }
      input {
        margin-right: 5px;
      }
      label {
        margin-bottom: 0;
        span {
          height: 35px;
          line-height: 35px;
          display: inline;
          margin-right: 10px;
        }
        img {
          height: 28px;
        }
        ul li {
          display: inline-block;
        }
      }
      .opt_desc {
        display: block;
        font-weight: normal;
        padding-left: 25px;
        width: 100%;
        font-style: italic;
      }
    }
  }
  .payment-item-wrapper {
    img {
      margin-right: 10px;
    }
    ul {
      display: inline-block;
    }
    li {
      display: inline;
    }
  }
}

#confirm {
  #print-order {
    margin-bottom: 1.4em;
    .print-confirm {
      width: 100%;
    }
  }
}

.samples-page {
  .samples-buttons {
    &.bottom {
      display: block;
    }
  }
}

.device-mobile#confirm {
  .confirm {
    #sidebar {
      padding: 0px 0px 10px 0px;
    }
  }
}

.cart-item__product-subline {
  a {
    color: #000;
    text-decoration: none;
  }
}

.product-info__sub_line {
  a {
    color: #000;
    text-decoration: none;
  }
}

.cart-item {
  &__desc {
    .cart-item__price {
      display: inline-block !important;
      visibility: visible;
      margin-left: 0px !important;
      padding-top: 0px !important;
      .cart-item__price-label {
        display: inline-block !important;
      }
    }
  }
  &__total {
    clear: both;
  }
}

.cart-item__size {
  display: block;
}

.checkout__content {
  .order-summary-panel {
    header {
      display: block;
      .order-summary-panel__title {
        text-align: left;
      }
    }
  }
  #review-address-info {
    .transaction-panel-details {
      .trans_detail_item:nth-child(2) {
        h4 {
          @include breakpoint($xsmall-up) {
            letter-spacing: 0.07em;
          }
        }
      }
    }
  }
}

.shipping-page-info-panel {
  .shipping-address-info {
    .sub_form {
      #choose-address {
        margin-top: 15px;
      }
    }
  }
}

#viewcart-panel {
  .viewcart-panel__title {
    font-size: 21px !important;
  }
  .messages {
    .error_messages {
      li {
        margin-left: 0;
        padding-bottom: 15px;
      }
    }
  }
}

#shipping {
  .main_container {
    .checkout {
      #shipping-page-info-panel {
        #checkout_shipping {
          fieldset.privacy-policy__fieldset {
            padding-bottom: 10px;
          }
        }
      }
      .checkout__content {
        section#shipping-page-info-panel {
          form#checkout_shipping {
            section#emailpromo-info-panel {
              fieldset.email_promotions.privacy_options {
                section.newsletter-info {
                  fieldset.newsletter-info__fieldset {
                    p.newsletter-info__text--new {
                      margin-left: 0px;
                    }
                    div.newsletter-info__item {
                      padding-left: 0px;
                      margin-left: 0px;
                    }
                  }
                }
              }
              fieldset.privacy-policy__fieldset {
                div.privacy-policy__item {
                  span.privacy-policy__required {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cart-items {
  .cart-item {
    .cart-item__thumb {
      text-align: #{$ldirection};
      .remove_link {
        color: $color-pink;
        position: relative;
        left: 7px;
        font-size: 12px;
        top: 0;
        border-bottom: 2px solid $color-pink;
      }
    }
  }
  .cart-item__desc-info {
    .color {
      .swatch {
        width: 22px !important;
      }
    }
  }
}

.checkout {
  .checkout__panel-title-container {
    text-align: left;
  }
}

#checkout_signin {
  input.return-user__submit {
    border-radius: 0;
  }
}

#checkout_registration_short {
  input.new-account__button {
    border-radius: 0;
    -webkit-appearance: none;
  }
}

#signin {
  input {
    -webkit-appearance: none;
  }
}

#offer-code-panel {
  input[type='submit'].offer_submit {
    margin-top: 8px;
  }
  .offer_code_box {
    #one-offer-only span {
      float: left;
    }
  }
}

.checkout-co-enabled {
  .pg_wrapper {
    .left {
      overflow: hidden;
      .viewcart-header {
        .checkout {
          &__panel-title {
            font-size: 18px !important;
            margin-top: 20px;
          }
        }
      }
      #viewcart-panel {
        .top-viewcart-buttons {
          height: 0;
          margin-bottom: 0;
        }
        .viewcart-panel {
          &__content {
            margin-bottom: 20px;
            .cart-items {
              .cart-item {
                &__remove-form {
                  .remove_link {
                    #{$ldirection}: auto;
                    color: $color-black;
                    font-size: 14px;
                  }
                }
                &__qty {
                  .qty_select {
                    padding-top: 0;
                  }
                }
                &__desc-info {
                  display: flex;
                  flex-direction: column;
                }
                &__price {
                  order: 4;
                }
                &__total {
                  padding-top: 3px;
                }
              }
            }
          }
        }
      }
      .viewcart-buttons-panel {
        .checkout_sticky {
          border-top: 2px solid $color-gray-border;
          box-shadow: 0 0 10px $color-gray-border;
          .continue-checkout {
            background-color: $color-pink;
          }
        }
      }
    }
    .right {
      .links-panel {
        &__title {
          border-top: 1px solid $color-black;
          letter-spacing: 0.1em;
          margin-top: 0px;
          padding: 15px 20px;
          padding-#{$rdirection}: 13px;
        }
        .links_list {
          padding: 0 15px 15px;
        }
        .expando-block {
          .expando-block {
            &__icon {
              #{$rdirection}: 6px;
            }
          }
        }
      }
    }
    .cart-item {
      &__total {
        margin: 0;
        padding: 0;
      }
    }
  }
  &#signin {
    .pg_wrapper {
      .sign-in-page {
        padding: 0;
        .section-head {
          .section-head {
            &__header {
              font-size: 18px !important;
              line-height: 1;
              border-bottom: 2px solid $color-black;
              padding-bottom: 16px;
              margin: 15px 0;
              text-transform: uppercase;
            }
          }
        }
        .checkout-required {
          display: none;
        }
        .new-account,
        .return-user {
          padding: 0 20px;
          .forgot-password {
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.15em;
            text-transform: uppercase;
          }
          &__fieldset {
            input {
              width: 100%;
              height: 48px;
              &[type='text'],
              &[type='password'] {
                margin-bottom: 15px;
              }
            }
            .btn {
              background-color: $color-pink;
            }
          }
          .newsletter-info {
            &__item {
              padding: 0;
              margin: 0;
            }
          }
          .label {
            font-size: 14px;
            display: flex;
            line-height: 1;
          }
          &__button,
          .btn {
            padding: 5px;
            height: 48px;
            font-size: 12px !important;
            font-weight: bold;
          }
          .btn {
            background-color: $color-pink;
            margin-bottom: 0;
          }
          &__button {
            background-color: $color-white;
            color: $color-black;
            letter-spacing: 0.1em;
            border: 1px solid;
          }
          &__text {
            font-weight: bold;
            letter-spacing: 0.1em;
          }
        }
        .column {
          padding: 0 20px;
          .sign-in-page {
            &__submit {
              margin-bottom: 20px;
            }
          }
        }
        .social_login {
          .social_login_v2 {
            &__social-login-container {
              .social-login-container {
                &__social-network-button {
                  padding: 15px !important;
                }
              }
            }
          }
          .label {
            font-size: 14px;
            display: flex;
          }
        }
        .account {
          &__return-user {
            padding: 0 20px;
          }
        }
        .new-account {
          &__fieldset {
            position: relative;
          }
        }
      }
    }
  }
  .emptycart {
    .empty-wrapper {
      &__link {
        border: 2px solid $color-black;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 3.2;
      }
    }
  }
}

.checkout {
  &__sidebar {
    .checkout {
      &__panel-title {
        &.expando-block {
          &__header {
            background: none;
          }
        }
      }
    }
  }
  .continue-shopping {
    color: $color-pink;
    border-bottom: 2px solid $color-pink;
    border-color: $color-pink;
  }
}
