.sign-in-page {
  input.return-user__submit,
  input[type='submit'],
  input[type='button'].new-account__button {
    width: 100%;
    margin-bottom: 20px;
  }
  .new-account,
  .return-user {
    input[type='password'] {
      margin-bottom: 20px;
    }
    input[type='text'],
    input[type='password'] {
      width: 100%;
      display: block;
    }
  }
  .return-user__link {
    display: block;
    margin-bottom: 20px;
  }
  .return-user,
  .new-account {
    &__text {
      margin-bottom: 10px;
    }
  }
}

.password-sent-page {
  &__content {
    .sent-info__text {
      margin: 0.8em 0;
    }
  }
}

.password-reset {
  &__item {
    margin: 0.8em 0;
    input[type='text'],
    input[type='password'] {
      display: block;
    }
  }
  &__text {
    margin: 0.8em 0;
  }
}

.account-landing-profile {
  &__header {
    span {
      background: $color-white;
      position: relative;
      padding: 0 7px 0 9px;
    }
  }
  &__img {
    .profile-pic {
      &__operations {
        text-align: center;
        a#profile-change-photo,
        a#profile-delete-photo {
          float: none;
        }
      }
    }
  }
}

#registration {
  .account__section {
    margin: 20px auto;
  }
  .password_help_text {
    margin: 10px 0px;
  }
  .optional-info__item {
    span {
      margin-right: 5px;
    }
  }
  .newsletter-info,
  .sms-promotions-container {
    float: left;
    padding: 10px;
  }
  .optional-info {
    padding: 10px;
    .birthday select {
      min-width: 80px;
    }
  }
  .newsletter-info {
    &__item {
      margin: 0 0 0 10px;
      padding: 10px;
    }
  }
  .main_container {
    .profile-page {
      #profile_preferences {
        .privacy-policy {
          padding: 10px;
        }
      }
    }
  }
  .account__section {
    .profile-page__content {
      .newsletter-info {
        &__text--new {
          margin-left: 0px;
        }
        &__item {
          padding-left: 0px;
          margin-left: 0px;
        }
        &__text {
          padding-left: 0px;
        }
      }
      .privacy-policy {
        &__required {
          display: none;
        }
      }
    }
  }
}

.favorites-page__content {
  padding: 15px;
  .favorites {
    .my-lists__list-actions {
      width: 48%;
      left: 0px;
      #share_ {
        display: none;
      }
    }
    a.my-lists__list-link--add-all {
      width: 165px;
      right: 0px;
    }
  }
  .favorites-page__head {
    .favorites-page__tabs {
      a {
        border-right: 0px;
        border-left: 0px;
        padding: 8px 5px;
      }
      .all {
        border-right: 1px solid $color-black;
        width: 40%;
      }
      .all.active {
        border-right: 0px;
      }
      .mylists {
        border-left: 1px solid $color-black;
        width: 60%;
      }
      .mylists.active {
        border-left: 0px;
      }
    }
  }
  h2 {
    &.favorites__instruction-step-title {
      line-height: 28px;
    }
  }
  .favorites-mylists {
    .favorites-mylists__content {
      .favorites-mylists__lists {
        .my-lists {
          .my-lists__list-link--share {
            display: none;
          }
        }
      }
    }
  }
}

.address-book-page {
  h1.address-book-page__header span {
    background: $color-white;
    display: inline-block;
    position: relative;
    padding: 0 7px 0 9px;
  }
  .address-book {
    width: 90%;
    margin-right: 10px;
    &__content {
      .payment-info__header {
        text-indent: 0px;
      }
    }
  }
  .payment-info {
    display: none;
  }
  .address-item {
    &__controls {
      a {
        border-bottom: 1px solid $color-gray-light;
        text-transform: uppercase;
        text-decoration: none;
        font-family: $brandon-text-bold;
        font-size: 11px;
        &.default-address__button {
          font-size: 14px;
          line-height: 1;
          margin-top: 6px;
        }
      }
    }
  }
}

.shipping-billing {
  .breadcrumb {
    margin: 20px;
  }
  .phone_label_container {
    padding: 10px;
  }
}

.address-form {
  &__fieldset {
    .city_container {
      input {
        width: 100%;
      }
    }
    .ac_list {
      height: 150px;
      overflow-y: scroll;
    }
  }
}

#colorbox {
  .profile-pic-upload {
    &__form-wrapper {
      #profile {
        .upload-file {
          &__value {
            padding-top: 0px;
          }
        }
      }
    }
  }
}

.field-menu {
  .menu__list {
    span.menu__link--lvl-2 {
      height: auto;
      font-weight: bold;
    }
    a.menu__link--lvl-2 {
      height: auto;
    }
    span.menu__link--lvl-1 {
      font-weight: bold;
    }
    a.menu__link--lvl-1 {
      font-weight: bold;
    }
  }
}

.customer-service-menu {
  .field-menu {
    a.menu__link--lvl-1 {
      height: auto;
    }
  }
}

.order-details-page__content {
  .order-products {
    .cart-item {
      .cart-item__total {
        float: right;
        margin-top: 10px;
      }
    }
  }
}

.field-elc-mpp-content {
  .mpp {
    .product-grid__content {
      .quickshop-wrapper {
        .quickshop--mobile {
          .selectbox {
            background-position: 110px 15px;
          }
        }
      }
    }
  }
}

.homepage-hero__slide:nth-child(3) {
  .homepage-hero__abstract {
    top: 40% !important;
    bottom: auto !important ;
  }
}

#order_detail {
  .order-info {
    .order-info__item:nth-child(1) {
      a {
        color: inherit !important;
        text-decoration: none;
        pointer-events: none;
      }
    }
  }
}

.site-content {
  .customer-service-section {
    #contact-about-content {
      .customer-service-contact-form__categories {
        li {
          padding: 1em 0px;
          letter-spacing: 0.2px;
          font-size: 12px;
        }
      }
    }
  }
}

.site-email-sms-signup {
  .site-email-sms-signup__header {
    h1 {
      font-size: 1.7em;
    }
  }
}

.site-footer__main {
  .site-email-signup__field {
    width: 49%;
  }
}

.site-utils__right {
  .site-utils__menu {
    .site-utils__item {
      .site-utils__link {
        letter-spacing: 1px;
      }
    }
  }
}

.past-purchases-page {
  a.order_history_back_link {
    padding: 15px 15px 15px 20px;
    display: block;
  }
  .past-purchases__content {
    padding: 0px;
  }
}

.favorites-page {
  a.favorites_back_link {
    padding: 15px;
    display: block;
  }
}

.address-book-page {
  a.address_back_link {
    padding: 15px 15px 15px 20px;
    display: block;
  }
}

.customer-service-shipping {
  .customer-service-section {
    #shipping-charges-content {
      table {
        table-layout: auto;
        td {
          padding: 0.725em 5px;
        }
      }
    }
  }
}

.past-purchases-page {
  .past-purchases__content {
    padding: 0 15px 5px 20px;
  }
}

.password-reset__item {
  input[type='text'],
  input[type='password'] {
    width: 100%;
  }
}

.address-form__fieldset,
.address.fs {
  .city_container,
  .address1_container {
    p#shipping-note {
      display: none;
    }
    .ac_list {
      height: auto;
      width: 100%;
      border: 1px solid #999999;
      background-color: #ffffff;
      z-index: 20;
      ul {
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
        overflow-x: hidden;
        height: auto;
        max-height: 300px;
        list-style-position: outside;
        list-style: none;
        padding: 0 !important;
        margin: 0 !important;
        li {
          margin: 0px;
          padding: 2px 5px !important;
          cursor: pointer;
          display: block;
          overflow: hidden;
          border-bottom: 1px solid #999999;
          color: #333333;
        }
      }
    }
  }
}

.change-password {
  .password-field {
    &__rules {
      column-count: auto;
    }
  }
}
