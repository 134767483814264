.page-products {
  .mpp {
    .product-grid {
      .product-grid__item {
        .product-thumb__abstract {
          .product-thumb__headline {
            word-wrap: break-word;
          }
        }
      }
    }
  }
  .mpp-header-content-block {
    .content-block__line--header {
      h1 {
        font-size: 35px;
      }
    }
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .subtotal {
      letter-spacing: 0.5px;
      .right-copy {
        .sub_t {
          width: 85px;
          &_text,
          .sub_num {
            padding: 0 5px;
          }
        }
      }
    }
    .cart-products {
      .prod {
        .prod-info {
          width: 43%;
        }
        .price {
          width: 25%;
        }
        .qty {
          margin-top: 3px;
        }
      }
    }
  }
}

.product-thumb {
  .product-thumb__cta {
    @include breakpoint($xsmall-up) {
      line-height: 15px;
      letter-spacing: 0.24em;
    }
    font-size: 12px !important;
    letter-spacing: 0.5px;
  }
  .product__button--add-to-bag {
    @include breakpoint($xsmall-up) {
      line-height: 15px;
      letter-spacing: 0.24em;
    }
    font-size: 12px !important;
    letter-spacing: 0.5px;
  }
}

.site-content {
  .customer-service-section {
    .customer-service-contact-form__privacy_policy {
      input[type='checkbox'].error ~ label {
        color: #ff0000;
      }
    }
  }
  .store-locator {
    .resp-tabs-list {
      display: none;
    }
  }
  #search-wrapper {
    #search-summary {
      p:first-child {
        display: none;
      }
      .search-results__no-results {
        p:first-child {
          display: block;
        }
      }
    }
    .endeca-product-result {
      height: auto !important;
    }
  }
  #dimensions {
    .results {
      .results-header:first-child {
        span {
          word-break: break-all;
        }
      }
    }
  }
  .search-form__results-count {
    display: none !important;
  }
}

.site-footer {
  .site-footer__main {
    position: relative;
    .site-footer__column:nth-child(4) {
      position: absolute;
      bottom: 10px;
      right: 0px;
      padding: 10px 20px 0 20px;
    }
    .trustmark-logo {
      width: 75px;
    }
    .site-footer__column {
      .country-select {
        margin-left: 20px;
      }
    }
  }
}

.spp-mobile-page {
  .spp__share {
    padding-bottom: 30px;
    .product__social {
      .share-twitter,
      .share-pinterest,
      .share-youtube,
      .share-instagram {
        display: none;
      }
    }
  }
}

.site-nav {
  .field-mobile-menu {
    .menu--lvl-1 {
      .menu--lvl-2 {
        .menu__list--lvl-2 {
          .menu__item--lvl-2 {
            font-size: 11px !important;
            letter-spacing: 0.1em;
            .menu__link--lvl-2 {
              padding-left: 30px;
              font-size: 12px !important;
              letter-spacing: 0.1em;
            }
          }
          .menu__item--lvl-3 {
            .menu__link--lvl-3 {
              padding-left: 30px;
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
}

.site-header {
  .site-utils__inner {
    .site-utils__links {
      .site-utils__menu {
        width: 100%;
      }
    }
  }
}

#colorbox {
  #cboxClose {
    width: 16px;
  }
}

.site-footer {
  .basic-responsive {
    .site-footer-toggle-device {
      display: none;
    }
  }
}

.offers-details {
  .offers-details-items {
    .offers-details-item__header {
      font-size: 17px;
      letter-spacing: 0em;
    }
    .offers-details-item__actions {
      .offers-details-item__link {
        padding: 6px;
      }
    }
  }
}

@media only screen and (min-device-width: 414px) and (orientation: landscape) {
  .homepage-hero {
    .slick-list {
      .homepage-hero__slide__inner {
        height: 640px;
      }
    }
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
  .homepage-hero {
    .slick-list {
      .homepage-hero__slide__inner {
        height: 583px;
      }
    }
  }
}

@media only screen and (max-device-width: 568px) and (orientation: landscape) {
  .homepage-hero {
    .slick-list {
      .homepage-hero__slide__inner {
        height: 491px;
      }
    }
  }
}

.field-mobile-template {
  #search-summary {
    .search-results__no-results {
      text-transform: uppercase;
      span {
        font-weight: bold;
      }
    }
  }
}

#search {
  #search-summary {
    .search-results__no-results {
      text-align: center;
      .homepage-hero__slide {
        h2.homepage-hero__headline {
          span {
            font-family: Times New Roman !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 340px) {
  header.page-header.page-header--bg-transparent.page-header--fit-to-img.page-header--has-subtitle {
    img.page-header__img {
      height: 174px !important;
    }
  }
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        .appt-book-mobile {
          font-size: 9px;
        }
      }
    }
  }
}

.appt-book.counters {
  .appt-book-page-header {
    .section-header__inner {
      line-height: 24px;
    }
  }
}

.homepage-hero__slide {
  h2.homepage-hero__headline {
    span {
      font-family: Times New Roman !important;
    }
  }
}
/* Power Reviews */

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: 'Да';
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: 'Нет';
            }
            &:after {
              @include breakpoint(($small-down) (orientation portrait)) {
                display: none;
              }
            }
          }
        }
      }
      @include breakpoint(($small-down) (orientation portrait)) {
        .pr-rd-footer {
          .pr-rd-helpful-action {
            margin-bottom: 0;
            .pr-helpful-btn {
              float: none;
            }
          }
          .pr-rd-flag-review-container {
            a {
              margin-left: 0;
            }
          }
          .pr-flag-review {
            .pr-flag-review-btn-cancel {
              margin-top: 10px;
            }
          }
        }
      }
    }
    .pr-snippet-stars-reco-inline {
      .pr-snippet-stars-reco-reco {
        .pr-snippet-reco-to-friend-percent {
          left: 50%;
        }
      }
      .pr-snippet-read-and-write {
        margin-top: 50px;
      }
    }
  }
}

#pr-war-form {
  .pr-media_image-form-group,
  .pr-media_videourl-form-group {
    label {
      width: 235px !important;
    }
  }
  .tag-group {
    .input-add-tag {
      width: 87%;
      @include breakpoint(($small-down) (orientation portrait)) {
        width: 76%;
      }
    }
  }
}

.quickshop {
  .product__divider {
    border-left: 0px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  #ship-calc-overlay {
    .close-popup {
      margin-left: 0 !important;
    }
  }
}

.shipping-calc-results {
  display: block;
  margin: 10px 18px;
  font-size: 16px;
  .shipping-cost {
    .country-container {
      float: left;
      margin: -22px 0px 10px 0px;
    }
  }
}

@media screen and (min-width: 767px) {
  .shipping-calc-results {
    .shipping-cost {
      .country-container {
        float: none !important;
        display: block;
      }
    }
  }
}

.device-mobile {
  .shipping-cost-widget {
    margin-bottom: -18px;
    p.delivery_region {
      padding-top: 12px;
    }
    .shipping-cost {
      margin: 1.5em 0;
    }
    .shipping-price {
      margin-right: 5px;
    }
    .shipping-title {
      font-weight: bold;
      margin-bottom: 8px;
    }
    .city_region {
      line-height: 20px;
    }
  }
  a.shipping_calc {
    text-decoration: underline;
    text-transform: uppercase;
  }
  #ship-calc-overlay {
    .country-container {
      float: left;
      width: 100%;
      margin-bottom: 10px;
      input,
      select {
        width: 100%;
        float: left;
        text-transform: uppercase;
      }
      span.label-content {
        text-transform: uppercase;
      }
      select {
        height: 33px;
      }
    }
    .calculation-result {
      .shipping-type {
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
    .close-popup {
      padding: 6px 10px;
      background: #000;
      margin-left: 15px;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      float: right;
      height: 31px;
    }
    .input-btn {
      border-radius: 0px !important;
    }
    .submit_popup {
      margin-top: 15px;
    }
    .missing_region,
    .missing_city {
      color: red !important;
    }
  }
  .shipping-calc-link {
    display: block;
    margin: 10px 18px;
    font-size: 16px;
  }
}

.lp_radio_button {
  input {
    display: none;
  }
}

#footer_sticky_chat {
  min-height: 65px;
}

#cboxWrapper {
  input.popup-offer__submit-input.js-popup-offer-submit {
    margin-top: 14px;
  }
}

.slick-next,
.slick-prev {
  font-size: 0 !important;
}

.slick-dots li {
  .offers-vip__button,
  button {
    font-size: 0 !important;
  }
}

.spp-product-layout {
  &__content-item {
    .spp-cross-sells {
      .product-grid--carousel {
        .product-grid {
          &__content {
            & > .slick-next,
            & > .slick-prev {
              top: 25%;
            }
          }
        }
      }
    }
  }
  .sticky-add-to-bag {
    &__status {
      .product {
        &__inventory-status {
          height: auto;
        }
      }
    }
  }
}

.sticky-add-to-bag__cta {
  .button {
    font-size: 11px;
  }
}
.page-product {
  .product-full__data-heading-snippet {
    padding-left: 20px;
  }
  .spp-product-layout__content-item-heading {
    font-size: 35px;
  }
}
.content-formatter {
  .content-block--basic-tout {
    .content-block__content {
      .content-block__content-text {
        .content-block__line--content--1 {
          .custom-text {
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.product-foundation-vto {
  &__container {
    ul.shade-list-vto {
      .swatches--single {
        a.swatch {
          background-color: $color-white;
        }
      }
      &.perfect-shades {
        width: 104%;
      }
    }
  }
  &__undertone-header {
    @include swap_direction(padding, 0 0 0 15px);
    line-height: 1.34;
  }
  &__undertone-importance-copy {
    @include swap_direction(padding, 0 0 13px 15px);
  }
  &__undertone-copy {
    @include swap_direction(padding, 0 0 0 15px);
  }
  &__undertone {
    margin-top: 10px;
  }
}
