.appointment-booking-schedule__overlay {
  .overlay-content-container {
    margin-top: -95px;
    width: 90%;
    margin: auto;
    left: 5%;
    top: 40%;
  }
}

.appt-book {
  #appointment-book-sections {
    .js-back-to-link,
    .js-start-over,
    #appt-book-book-submit,
    .js-appt-book-submit,
    .appointment-booking-review__account-signup-form fieldset,
    .appointment-booking-review__account-signup-form input,
    .signup__note-textarea {
      font-size: 12px !important;
    }
    .appt-book-content-header {
      .section-header {
        padding: 0;
      }
    }
    .appointment-booking-review__account-signup-form {
      .signup__mobile-prefix {
        width: 55px !important;
      }
      .signup__mobile-phone {
        padding-left: 47px !important;
      }
    }
    .confirmation {
      .confirmation-container {
        .appt-date-cal {
          .add-cal-link {
            padding-left: 10%;
            padding-right: 10%;
          }
        }
      }
    }
    .service-select {
      .book-now-bar {
        .next {
          width: 50% !important;
        }
      }
    }
    .appointment-booking-review__services.row {
      .service__details {
        padding-right: 33%;
      }
    }
  }
}

.account {
  .appointments-page {
    &.account__section {
      margin-left: 2%;
      margin-right: 2%;
      .appt-book {
        .my-appointments {
          .appt-book-no-appts-content {
            .no-appts-content {
              #appt-book-btn {
                margin-top: 40px !important;
              }
            }
          }
          .appointment-container {
            .appointment-details {
              .your-lessons {
                text-align: $ldirection;
              }
            }
          }
        }
      }
    }
  }
}
